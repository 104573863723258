<template>
  <v-card class="mt-2 ml-2 mr-2">

    <v-tabs :vertical="$vuetify.breakpoint.mdAndUp" :centered="$vuetify.breakpoint.smAndDown" color="deep-purple accent-4" background-color="#F5F5FF5F">
      <v-tab  class="justify-md-start" disabled>
        <v-icon left>
          {{firstTabIcon}}
        </v-icon>
        {{ firstTabTitle }}
      </v-tab>
      <v-tab class="justify-start" @click="tabUsersClick" active-class="">
        <v-icon left>
          mdi-account-multiple-outline
        </v-icon>
        Users
      </v-tab>

      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <p>
              Application Setting
            </p>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <UsersTable ref="refUsersTable"/>
          </v-card-text>
        </v-card>
      </v-tab-item>

    </v-tabs>

  </v-card>
</template>

<script>
import UsersTable from "../../components/setting/UsersTable";


export default {
  name: "SettingAndUser",
  components: {UsersTable},
  data() {
    return {
      firstTabTitle: 'OPTIONS',
      firstTabIcon: ' mdi-cog-outline'
    }
  },
  methods: {
    tabUsersClick(){
      try {
        this.$refs.refUsersTable.fetchParent()
      }catch (e) {
        e.toString()
      }
    },
    tabCompanyClick(){
      try {
        this.$refs.refCompanyTable.fetchParent()
      }catch (e) {
        e.toString()
      }
    },
    tabDivisionClick(){
      try {
        this.$refs.refDivisionTable.fetchParent()
      }catch (e) {
        e.toString()
      }
    },
    tabSalesmanClick(){
      try {
        this.$refs.refSalesmanTable.fetchParent()
      }catch (e) {
        e.toString()
      }
    },
  }
}
</script>

<style scoped>
</style>